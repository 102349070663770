<script setup lang="ts">
  const { showHelpModal, isHelpMessageSendedSuccess } = storeToRefs(useBookingStore());

  const closeForm = () => {
    showHelpModal.value = false;
    isHelpMessageSendedSuccess.value = false;
  };
</script>

<template>
  <div class="form-sended-container">
    <div class="close-container" @click="closeForm">
      <IconClose class="icon-close" />
    </div>
    <div class="info-block-container">
      <div class="info-block">
        <span class="info-block-text-bold">Скоро позвоним</span>
        <span class="info-block-text">
          Мы работаем с 08:00 до 21:00 и обычно связываемся в течение часа
        </span>
        <button class="info-block-button" @click="closeForm">
          Хорошо
        </button>
        <PatientHelpFormModalFormContacts class="contacts-container" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-sended-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-92%);
  width: 600px;
  height: 276px;
  background: #25dd80 url(~/assets/img/form-sended-successfully.png);
  background-position: 50% 50%;
  border-radius: 24px 24px 0px 0px;
  @media (min-width: 1023px) {
    &::before {
      content: "";
      position: relative;
      display: flex;
      top: 275px;
      left: 0;
      width: 100%;
      min-height: 230px;
      background-color: white;
      border-radius: 0px 0px 24px 24px;
    }
  }
  @media (max-width: 1023px) {
    transform: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 276px;
    background: #25dd80 url(~/assets/img/form-sended-successfully.png);
    background-position: 50% 50%;
    border-radius: none;
  }
}
.close-container {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.info-block-container {
  position: absolute;
  left: 0;
  top: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 20px;
  @media (max-width: 1023px) {
    position: absolute;
    left: 0;
    top: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
  }
}
.info-block {
  margin: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.10);
  background-color: white;
}
 .info-block-text-bold {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 130%;
  }
 }
 .info-block-text {
  margin-top: 12px;
  margin-bottom: 64px;
  text-align: center;
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (min-width: 1023px) {
    margin-bottom: 30px;
  }
 }
 .info-block-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 48px;
  padding: 15px 16px;
  border-radius: 15px;
  background-color: #2D7FF9;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 1023px) {
    width: 100%;
  }
 }
 .contacts-container {
  position: fixed;
  bottom: -12.8rem;
  width: 80%;
  @media (max-width: 1023px) {
    position: absolute;
    bottom: -8.5rem;
    width: 100%;
  }
  @media (max-width: 375px) {
    position: absolute;
    bottom: -12rem;
    width: 100%;
  }
 }
</style>
